<template>
  <div
    :class="$style.root"
    v-animated-underline-mouse-events
  >
    <i18n-link
      :to="{ name: 'publications-slug', params: { slug: entry?.slug }, query: route?.query }"
      :class="$style.link"
    >
      <div>
        <div :class="$style.category">
          {{ category }}
        </div>

        <div
          :class="$style.title"
          :style="cssBackgroundColorTextDecoration"
          data-inverted
        >
          {{ titleTruncated }}
        </div>

        <div
          v-if="relation?.title"
          :class="$style.relation"
        >
          {{ relation?.title }}
        </div>
      </div>
    </i18n-link>

    <i18n-link
      v-if="coverImage"
      :to="{ name: 'publications-slug', params: { slug: entry?.slug }, query: route?.query }"
      :class="$style.imageLink"
    >
      <shared-publication-image-component
        :image="coverImage"
        :width-in-mm="entry?.widthInMm"
        :class="$style.image"
      />
    </i18n-link>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()
import i18nLink from '~/components/i18n/link.vue'

const props = defineProps({
  entry: {
    type: Object
  }
})

const coverImage = props.entry?.coverImage
const file = props.entry?.file

const relation = props.entry?.biennialEntry?.data || props.entry?.biennialEntry

const fileUrl = getAbsoluteFileUrl(file?.url, file?.provider)
const category = props?.entry?.publicationCategory?.data?.title || props?.entry?.publicationCategory?.title

const colors = relation?.colors
const cssBackgroundColorTextDecoration = getCssBackgroundColorTextDecoration(colors)

const titleTruncated = baseTruncateString(props?.entry?.title, 8)
</script>

<style module>
.root {
  composes: font-size-small from global;

  width: var(--width--thumbnail--2-4);
  max-width: var(--max-width--thumbnail);

  padding: 0 var(--unit--horizontal);

  display: flex;
  flex-direction: column;
  gap: var(--unit--vertical);
}

.root:focus-within .image {
  outline: var(--outline--focus);
}

.link {
  composes: reset-link from global;
}

.title {
  composes: font-size-medium from global;
  composes: animated-underline from global;
}

.relation {
  color: var(--color--secondary--default);
}

.category {
  color: var(--color--secondary);
}
</style>
